import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/es";

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.locale("es"); // initial locale

export const getDayPeriodString = (date: string | undefined) => {
  const hour = dayjs(date).format("h");
  if (parseInt(hour) >= 12) return "common.inTheAfternoon";
  return "common.inTheMorning";
};

export default dayjs;
